import React, { useEffect, useRef, useState } from 'react';

import {
  Checkbox,
  CheckboxGroup,
  RadioButton,
  RadioButtonGroup,
  Divider,
  Popover,
  PopoverTriggerAction as TriggerAction,
  Text,
  TextButton,
  TextButtonPriority as TEXT_BUTTON_PRIORITY,
} from 'wix-ui-tpa/cssVars';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { ChevronDown as CaretDown } from '@wix/wix-ui-icons-common/on-stage';
import { ClearXSmall } from '@wix/wix-ui-icons-common/on-stage/system';
import { classes, st } from './Filters.st.css';
import { FiltersDataHooks } from './dataHooks.const';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import {
  FilterOption,
  FilterTypes,
  FilterViewModel,
} from '../../../ViewModel/filterViewModel/filterViewModel';
import {
  WidgetComponents,
  WidgetElements,
} from '../../../../../utils/bi/consts';
import { useSettingsParams } from '../../../Hooks/useSettingsParams';
import { SrOnly } from '../../../../../utils/accessibility/SrOnly/SrOnly';
import {
  getSelectedOptions,
  getSelectedOptionsFromList,
  optionsHaveChildren,
} from './utils';
import { MobileFilters } from './MobileFilters';

type FilterContentProps = {
  toggleOption: Function;
  options: FilterOption[];
  note?: string;
  maxOptionsToSelect?: number;
};

type CTAProps = {
  id: FilterTypes;
  selectedOptions: FilterOption[];
  label: string;
  open: boolean;
  onClick: () => void;
  ctaRef: React.MutableRefObject<any>;
};

type FilterProps = {
  filterViewModel: FilterViewModel;
};

const toggleOptionInList = (
  list: FilterOption[],
  clickedOption: FilterOption,
  maxOptionsToSelect: number | undefined = undefined,
) =>
  maxOptionsToSelect === 1
    ? [clickedOption]
    : clickedOption.selected
    ? list.filter(
        (option) => option.selected && option.value !== clickedOption?.value,
      )
    : list.filter(
        (option) => option.selected || option.value === clickedOption?.value,
      );

const Filter: React.FC<filterprops> = ({ filterViewModel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef(null) as React.MutableRefObject<any>;
  const ctaRef = useRef(null) as React.MutableRefObject<any>;
  const escPressedRef = useRef(null) as React.MutableRefObject<any>;
  const { isRTL } = useEnvironment();
  const { onFilterChanged, onElementClicked } = useCalendarActions();
  const PopoverElement = Popover.Element!;
  const PopoverContent = Popover.Content!;
  const { options, id, label, note, maxSelectionNumber } = filterViewModel;
  const selectedOptions = getSelectedOptions(filterViewModel);

  useEffect(() => {
    if (isOpen) {
      requestAnimationFrame(() => popoverRef.current?.focus());
    } else if (escPressedRef.current) {
      escPressedRef.current = false;
      ctaRef.current.focus();
    }
  }, [isOpen]);

  const setFilterOptionsVisibility = (isVisible: boolean) => {
    onElementClicked(WidgetComponents.FILTER, WidgetElements.FILTER_BUTTON, {
      filterType: id,
    });
    setIsOpen(isVisible);
  };

  const onEscPress = () => {
    escPressedRef.current = true;
    setFilterOptionsVisibility(false);
  };

  const onClick = () => {
    setFilterOptionsVisibility(!isOpen);
  };

  const toggleOption = (clickedOption: FilterOption) => {
    const optionsToSelect = optionsHaveChildren(options)
      ? options.reduce((acc, option) => {
          if (option.value === clickedOption.value) {
            return option.selected ? acc : acc.concat(option.children!);
          } else if (option.children?.includes(clickedOption)) {
            return acc.concat(
              toggleOptionInList(option.children!, clickedOption),
            );
          } else {
            return acc.concat(getSelectedOptionsFromList(option.children!));
          }
        }, [] as FilterOption[])
      : toggleOptionInList(options, clickedOption, maxSelectionNumber);

    const updatedFilter = {
      [id]: optionsToSelect.map(({ value }) => value),
    };
    onElementClicked(
      WidgetComponents.FILTER,
      WidgetElements.CHECKBOX,
      updatedFilter,
    );
    onFilterChanged(updatedFilter);
  };

  const cta = (
    <cta id="{id}" selectedOptions="{selectedOptions}" label="{label}" open="{isOpen}" onClick="{onClick}" ctaRef="{ctaRef}"></cta>
  );

  const content = (
    <filtercontent toggleOption="{toggleOption}" options="{options}" note="{note}" maxOptionsToSelect="{maxSelectionNumber}"></filtercontent>
  );

  devolver (
    <popover @ts-expect-error="" ref="{popoverRef}" shown="{isOpen}" placement="{isRTL" ?="" 'bottom-end'="" :="" 'bottom-start'}="" dynamicWidth="" triggerAction="{TriggerAction.click}" showArrow="{false}" onClickOutside="{()" ==""> setFilterOptionsVisibility(false)}
      onEscPress={() => onEscPress()}
      onTabOut={() => setFilterOptionsVisibility(false)}
      className={classes.popover}
      tabIndex={-1}
      role="group"
      aria-labelledby={id}
      data-hook={id}
    >
      <popoverelement>
        <div className="{classes.filterWrapper}">{cta}</div>
      </popoverelement>
      <popovercontent>
        <div className="{classes.desktopContentWrapper}">{content}</div>
      </popovercontent>
    </popover>
  );
};

export type FiltersProps = {
  filterViewModels: FilterViewModel[];
};

const CTA: React.FC<ctaprops> = ({
  open,
  onClick,
  selectedOptions,
  label,
  id,
  ctaRef,
}) => {
  const { t } = useTranslation();

  function getSelectedValue() {
    switch (selectedOptions.length) {
      case 0:
        return t('filter.all-options.label');
      case 1:
        return selectedOptions[0].label;
      default:
        return String(selectedOptions.length);
    }
  }

  const getLabel = () =>
    t('filter.cta.label', { label, value: getSelectedValue() });

  return (
    <div data-hook="{FiltersDataHooks.FILTER_CTA}">
      <textbutton id="{id}" ref="{ctaRef}" aria-label="{label}" aria-haspopup="dialog" aria-expanded="{open}" data-hook="{FiltersDataHooks.FILTER_CTA" +="" id}="" className="{st(classes.filterCTA," {="" open="" })}="" priority="{TEXT_BUTTON_PRIORITY.secondary}" suffixIcon="{<CaretDown"></textbutton>}
        onClick={onClick}
        contentClassName={classes.filterCTAContent}
      >
        {getLabel()}
      
    </div>
  );
};

const FilterOptionCheckbox = ({
  option,
  indented,
  toggleOption,
  disableOption = false,
}: {
  option: FilterOption;
  indented?: boolean;
  toggleOption: FilterContentProps['toggleOption'];
  disableOption?: boolean;
}) => {
  const { value, selected, indeterminate, label, srOnlyLabel, children } =
    option;

  return (
    <checkbox disabled="{disableOption}" data-hook="{FiltersDataHooks.OPTION" +="" value}="" className="{st(classes.item," {="" indented:="" Boolean(indented),="" parent:="" Boolean(children),="" })}="" onChange="{()" ==""> toggleOption(option)}
      checked={seleccionado}
      indeterminate={indeterminado}
      key={valor}
      label={
        srOnlyLabel ? (
          <span>
            <span aria-hidden="true">{etiqueta}</span>
            <sronly data-hook="{FiltersDataHooks.ALL_SR_LABEL}">
              {srOnlyLabel}
            </sronly>
          </span>
        ) : (
          label
        )
      }
    />
  );
};

const FilterOptionRadio = ({
  option,
  toggleOption,
}: {
  option: FilterOption;
  toggleOption: FilterContentProps['toggleOption'];
}) => {
  const { value, selected, label } = option;

  return (
    <radiobutton data-hook="{FiltersDataHooks.OPTION" +="" value}="" className="{st(classes.item)}" onChange="{()" ==""> toggleOption(option)}
      checked={selected}
      key={value}
      label={label}
      value={value}
    />
  );
};
export const FilterContent: React.FC<filtercontentprops> = ({
  toggleOption,
  options,
  note,
  maxOptionsToSelect,
}) => {
  const { t } = useTranslation();
  const shouldUseRadioButton = maxOptionsToSelect === 1;
  const shouldDisabledNonSelectedOptions = maxOptionsToSelect
    ? options.filter((option) => option.selected).length >= maxOptionsToSelect
    : false;
  const shouldDisplayLimitationText =
    maxOptionsToSelect && options.length > maxOptionsToSelect;
  return (
    <>
      {shouldUseRadioButton ? (
        <radiobuttongroup className="{classes.checkboxGroup}" name="radioButtonFilter" onChange="{()" ==""> {}}
        >
          <ul>
            {options.map((opción) => (
              <li key="{option.value}">
                <filteroptionradio option="{option}" toggleOption="{toggleOption}"></filteroptionradio>
              </li>
            ))}
          </ul>
        </radiobuttongroup>
      ) : (
        <>
          {shouldDisplayLimitationText && (
            <text className="{classes.filterSelectionLimitation}" data-hook="{FiltersDataHooks.FILTER_LIMITATION_TEXT}">
              {t('filter.select-limitaion.text', {
                maxNumber: maxOptionsToSelect,
              })}
            </text>
          )}

          <checkboxgroup className="{classes.checkboxGroup}">
            <ul>
              {options.map((opción) => (
                <li key="{option.value}">
                  <filteroptioncheckbox option="{option}" toggleOption="{toggleOption}" indented="{false}" disableOption="{" shouldDisabledNonSelectedOptions="" &&="" !option.selected="" }=""></filteroptioncheckbox>
                  {option.children && (
                    <ul>
                      {option.children.map((child) => (
                        <li key="{child.value}">
                          <filteroptioncheckbox option="{child}" toggleOption="{toggleOption}" indented="{true}"></filteroptioncheckbox>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </checkboxgroup>
        </>
      )}
      {note && (
        <>
          <divider data-hook="{FiltersDataHooks.NOTE_DIVIDER}" className="{classes.noteDivider}"></divider>
          <text data-hook="{FiltersDataHooks.NOTE}">{nota}</text>
        </>
      )}
    </>
  );
};

const Filters: React.FC<filtersprops> = ({ filterViewModels }) => {
  const settings = useSettings();
  const settingsParams = useSettingsParams();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { onFilterChanged, onElementClicked } = useCalendarActions();

  const hasActiveFilter = () =>
    filterViewModels.some((filter) => getSelectedOptions(filter).length > 0);

  function clearFilters() {
    onElementClicked(WidgetComponents.FILTER, WidgetElements.CLEAR);
    onFilterChanged(undefined);
  }

  return filterViewModels.length ? (
    isMobile ? (
      <div data-hook="{FiltersDataHooks.MOBILE_FILTERS_WRAPPER}" className="{st(classes.root," {="" direction:="" 'column',="" alignment:="" settings.get(settingsParams.textAlignment),="" isMobile,="" })}="">
        <mobilefilters filterViewModels="{filterViewModels}"></mobilefilters>
      </div>
    ) : (
      <>
        <divider data-hook="{FiltersDataHooks.FILTER_DIVIDER}" className="{classes.divider}"></divider>
        <div data-hook="{FiltersDataHooks.FILTERS_WRAPPER}" className="{st(classes.root," {="" direction:="" 'row',="" alignment:="" settings.get(settingsParams.textAlignment),="" })}="" role="group" aria-label="{t('filters.aria-label')}">
          <text className="{classes.filterBy}" data-hook="{FiltersDataHooks.LABEL}">
            {t('filters.filter-by.label')}
          </text>
          {filterViewModels.map((filterViewModel) => (
            <filter key="{filterViewModel.id}" filterViewModel="{filterViewModel}"></filter>
          ))}
          {hasActiveFilter() && (
            <div className="{classes.filterWrapper}">
              <textbutton suffixIcon="{<ClearXSmall"></textbutton>}
                className={classes.clearFilters}
                priority={TEXT_BUTTON_PRIORITY.secondary}
                data-hook={FiltersDataHooks.CLEAR_FILTERS_CTA}
                onClick={clearFilters}
              >
                {t('filters.clear-filters.cta')}
              
            </div>
          )}
        </div>
      </>
    )
  ) : null;
};

export default Filters;
</filtersprops></filtercontentprops></radiobutton></checkbox></ctaprops></any></any></any></filterprops></any>